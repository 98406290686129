.ScreenTransitioner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  list-style: none; }


.Screen {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1200ms ease-in;
  width: 100%;
  height: 100%; }

.ActiveScreen {
  opacity: 1; }
