.office {
  font-family: 'Roboto';
  font-weight: 800;

  .h1 {
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;

    h1 {
      font-size: calc(80 / 1100 * 100vh);
      padding-top: 50px;
      letter-spacing: 0.1em;
      text-transform: uppercase; }

    h2 {
      font-size: calc(40 / 1100 * 100vh);
      padding-top: 25px;
      margin: -0.5em 0 2em 0; }



    svg {
      position: relative;
      top: 75px;
      right: calc(287 / 1100 * 100vh);
      max-width: 3%;
      transform: rotate(-5deg);
      justify-self: center; } }

  .Summary {
    color: #00ffce;
    margin-left: 0.1em;
    line-height: 1.2em; }

  .Office__plan {
    background-color: transparent;
    margin: 0;
    height: 80vh;
    width: 98vw;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

  svg {
    display: flex;
    width: 30%;
    z-index: 20; }

  .ghost__green {
    color: #33CC9e;
    animation: rainbow 5s linear infinite; }

  .ghost__pink {
    color: #ff0099;
    animation: rainbow 5s -2s linear infinite; }

  @keyframes rainbow {
    0% {
      color: #ff0099; }
    20% {
      color: #ff9900; }
    40% {
      color: #99ff00; }
    60% {
      color: #0099ff; }
    80% {
      color: #9900ff; }
    100% {
      color: #ff0099; } }

  .deskBooked {
    fill: rgba(218, 52, 77, 0.2); }

  .deskAvailable {
    fill: rgba(0, 255, 206, 0.2); } }
