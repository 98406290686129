body, html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit;
  cursor: none !important; }

body {
  background-color: #2F2F2F;
  color: #fff; }

h1 {
  text-align: center;
  font-size: calc(80 / 1100 * 100vh);
  padding-top: 50px;
  margin-bottom: 40px;
  font-weight: 800;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase; }
