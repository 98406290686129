.diashowScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .h1 {
    text-align: center;

    h1 {
      font-size: calc(80 / 1100 * 100vh);
      padding-top: 20px;
      font-weight: 800;
      font-family: 'Work Sans', sans-serif;
      letter-spacing: 0.1em;
      text-transform: uppercase; } }

  .h2 {
    h2 {
      font-size: calc(30 / 1100 * 100vh);
      padding-top: 50px;
      margin-bottom: .2em;
      font-weight: 800;
      font-family: 'Work Sans', sans-serif;
      letter-spacing: .1em;
      text-transform: uppercase; } }

  .header {
    svg {
      position: relative;
      top: 86px;
      max-width: 2vw;
      transform: rotate(-5deg);
      justify-self: center;
      left: calc(373 / 1100* 100vh); } }

  .diashowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2vw);
    height: calc(100% - 2vh);

    .diashowImagesContainer {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 1vw;

      @mixin diashowImageGen($count) {
        aspect-ratio: 1/1;
        max-height: 70vh;
        // max-width: 50vw
        max-width: calc(100vw / #{$count} - #{$count}*1vw);
        object-fit: cover; }

      .diashowImage1 {
        @include diashowImageGen(1); }

      .diashowImage2 {
        @include diashowImageGen(2); }

      .diashowImage3 {
        @include diashowImageGen(3); }

      .diashowImageMore {
        @include diashowImageGen(4); } } }

  .diashowPreviewContainer {
    display: flex;
    height: 14vh;
    width: 100%;
    overflow: hidden;
    gap: 1vh;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 1vh;

    .diashowPreviewImage {
      height: 8vh;
 } }      // width: 8vh

  .diashowImageCounter {
    position: absolute;
    bottom: 1vh;
    right: 1vh;
    color: darkgrey; }

  .diashowNoImages {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center; } }


