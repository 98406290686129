ul {
  list-style: none;
  margin-left: 0;
  line-height: 1.3;
  padding: 0.75em 1em; }

li {
  font-weight: bold;
  font-size: 0.75em;
  padding: 0.75em 0; }

.h2 {
  width: 100%;
  text-align: center;
  font-size: calc(30 / 1100 * 100vh);
  padding-top: .5em;
  margin-bottom: .2em;
  font-weight: 800;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: .1em;
  text-transform: uppercase; }

.InfoLeft, .InfoRight {
  top: 15%;
  height: 100%;
  max-width: 25ch;
  width: 25ch;
  background: rgba(0,0,0,0.3);
  font-size: 1.1vw;

  // > .h2
  //   font-family: 'Source Code Pro'

  sup {
    text-orientation: mixed;
    writing-mode: vertical-lr;
    transform: translateY(3px);
    font-size: 0.525em; } }

.ListItem {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #2F2F2F;
  padding: 1.75em 1.75em 1.25em; }

.ListItemMarker {
  position: absolute;
  top: 0.3125em;
  left: 0.3125em;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.85em 1.85em 0 0;
  border-color: transparent transparent transparent transparent; }

.InfoRight {
  right: 2%;
  left: auto; }

.Empty {
  color: grey;
  font-weight: 600;
  font-size: calc(20 / 1100 * 100vh);
  text-transform: uppercase; }

.Location {
  display: flex;
  align-items: center;
  margin-top: 0.35em;
  color: #686c71; }

.LocationDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: 0.3125em; }

.Time {
  text-align: right;
  color: #a6a7a6;
  margin-top: 0.4em; }

.Progress {
  margin-top: 5px;
  height: 5px;
  position: relative;
  background: #555;

  span {
    display: block;
    height: 100%;
    background-color: #00ffce;
    position: relative;
    overflow: hidden; } }
