.Vacations {
  background: #2F2F2F;
  height: 100vh;
  font-family: Roboto;

  .Content {
    .h1 {
      svg {
        position: relative;
        top: 75px;
        right: calc(349 / 1100 * 100vh);
        max-width: 3%;
        transform: rotate(-5deg);
        justify-self: center; } } } }


.Content {
  width: calc(100vw - 10%);
  height: 100vh;
  margin: 0 auto;
  position: relative;

  .h1 {
    text-align: center;

    h1 {
      font-size: calc(80 / 1100 * 100vh);
      padding-top: 50px;
      margin-bottom: 40px;
      font-weight: 800;
      font-family: 'Work Sans', sans-serif;
      letter-spacing: 0.1em;
      text-transform: uppercase; } }


  .Summary {
    color: #00ffce;
    margin-left: 0.1em; }

  ul {
    list-style-type: none; }

  li {
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 130px; } }

.Date {
  position: relative;
  font-size: 40px;
  height: 72px;
  display: grid;
  grid-template-columns: 74px auto;
  grid-column-gap: 16px;
  align-items: center; }

.Day {
  text-align: center;
  font-family: Roboto;
  letter-spacing: .1em;
  border: 4px solid #4b4b4b;
  border-radius: 5px / 10px;
  padding: 5px 5px 5px 8px;
  &:before {
    content: '';
    height: 4px;
    width: 60px;
    background: #2F2F2F;
    display: block;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-3.4px); } }

.Float {
  float: left;
  width: 50%; }

.People {
  display: grid;
  grid-template-columns: repeat(3, calc((100vw - 10%) / 3));
  grid-auto-rows: 140px;
  align-items: center; }

.Reason {
  width: 48px;
  height: auto;
  grid-column: 1;
  transform: translateY(18px); }

.Person {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 70px 30px;
  align-items: center; }

.Name {
  font-size: 43px;
  position: relative;
  white-space: nowrap;
  height: 53px;
  grid-column: 2 / 4;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 370px; }

.Till {
  grid-column: 2 / 4;
  display: block;
  font-size: 0.8em;
  color: #aaa;
  text-transform: lowercase;
  font-weight: 400; }

.Imgly {
  width: 48px;
  height: 48px;
  margin-left: 8px;
  transform: translateY(-2px); }

.Legend {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center; }

.LegendInfo {
  display: flex;
  height: 40px;
  width: 150px;
  align-items: center;
  justify-content: center; }

.Dot {
  display: flex;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: #ffffff;
  margin-right: 10px; }

.Green {
  background: #13b7a1; }

.HideSecond {
  li:nth-child(2) {
    display: none; } }
