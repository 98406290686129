.CalendarScreen {
  position: relative;
  z-index: 1;
  font: 54px "Lucida Grande", Helvetica, Arial, sans-serif;
  margin: 10px 20px;

  span {
    color: #00ffce;
    margin-left: 0.1em; } }

.Entry {
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 35px 0px; }

.LowEntry {
  opacity: 0.5;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 35px 0px; }

.Left {
  float: left;
  width: 21%;

  &.Highlight {
    animation: flash 2s infinite; } }

.Center {
  display: inline-block;
  width: 69%; }

.Right {
  float: right;
  width: 10%; }

.Small {
  margin: 1em 0;
  font-size: 30px;
  color: #666; }

.Highlight {
  font-size: 68px; }

.Tomorrow {
  width: 100%;
  text-align: center;
  font-size: calc(80 / 1100 * 100vh);
  padding-top: 50px;
  margin-bottom: 40px;
  font-weight: 800;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  svg {
    position: relative;
    top: -35px;
    right: -33px;
    max-width: 3%;
    transform: rotate(-5deg); } }

.WeatherIcon {
  margin: -10px 0 0 0px;
  width: 140px;
  filter: invert(.8); }

.WeatherIconLarge {
  width: 100%;
  filter: invert(.8);
  margin-top: -20px;
  margin-right: -10px; }

.Temperature {
  opacity: 0.5;
  margin-top: -40px;
  font-size: 28px;
  text-align: center; }

@keyframes flash {
  0%, 100% {
    opacity: 1.0; }
  50% {
    opacity: 0.3; } }
